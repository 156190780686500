export const HISTORY_TYPES = {
  PUSH: "push",
  POP: "pop",
  REPLACE: "replace"
}

export const NAVIGATION_EVENTS = {
  PUSH: "push",
  POP: "pop"
}
