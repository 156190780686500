/* eslint-disable no-console */

import { register } from "register-service-worker"

import EventBus, { EVENTS } from "@/lib/event-bus"

import isServiceWorkerContext from "@/lib/is-platform/is-service-worker-context"

if (isServiceWorkerContext()) {
  register(`/service-worker.js`, {
    ready() {
      // console.log(
      //   "App is being served from cache by a service worker.\n" +
      //     "For more details, visit https://goo.gl/AFskqB"
      // )
    },
    registered() {
      // console.log("Service worker has been registered.")
    },
    cached() {
      // console.log("Content has been cached for offline use.")
    },
    updatefound() {
      console.log("SW: Update Found, Downloading...")
    },
    updated(registration) {
      console.log("SW: Update Available")

      registration.waiting.postMessage({ type: "SKIP_WAITING" })
      EventBus.$emit(EVENTS.swUpdateAvailable)
    },
    offline() {
      // console.log(
      //   "No internet connection found. App is running in offline mode."
      // )
    },
    error() {
      // console.error("Error during service worker registration:", error)
    }
  })
}
