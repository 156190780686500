// TODO: Move to /shared/constants.js
export const SW_UPDATE_QUERY_PARAM = "__update__"
export const AUTH_QUERY_PARAM = "__auth__"
export const PREVIEW_QUERY_PARAM = "__preview__"
export const HIDE_QUERY_PARAM = "__hide__"
export const LIGHT_BOX_KEY = "mklbItem"
export const DEPTH_OF_THREAD = 1

export const HIDDEN_ELEMENTS = {
  TABBAR: "tabbar",
  TOOLBAR: "toolbar",
  PRODUCT: "product",
  COUPON: "coupon",
  AMOUNT: "amount",
  ORDER_SUMMARY: "order-summary"
}

export const MANAGED_PAGE_PREFIX = "/me/"
