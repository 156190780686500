import { HISTORY_TYPES } from "@/plugins/router/shared"

export default {
  namespaced: true,

  state: {
    stack: [],
    queue: [],
    historyType: HISTORY_TYPES.PUSH,
    initialized: false
  },

  mutations: {
    setHistoryType(state, type) {
      state.historyType = type
    },

    push(state, path) {
      // Return early for duplicate route
      if (
        state.historyType === HISTORY_TYPES.PUSH &&
        state.stack[state.stack.length - 1] === path
      ) {
        return
      }

      if (state.historyType === HISTORY_TYPES.REPLACE) {
        state.stack[state.stack.length - 1] = path
      } else {
        state.stack.push(path)
      }
    },

    pop(state) {
      state.stack.splice(state.stack.length - 1)
    },

    reset(state) {
      state.stack = []
      state.queue = []
      state.initialized = false
      state.historyType = HISTORY_TYPES.PUSH
    },

    initialize(state, path) {
      state.stack = [path]
      state.initialized = true
    },

    queuePush(state, path) {
      if (state.queue.includes(path)) return

      state.queue.push(path)
    },

    queueRemove(state, path) {
      state.queue = state.queue.filter(item => item !== path)
    }
  },

  getters: {
    getPageIndex(state) {
      return route => {
        for (let index = 0; index < state.stack.length; index++) {
          if (state.stack[index] === route.path) {
            return index
          }
        }

        return -1
      }
    },

    stack(state) {
      return state.stack
    },

    historyType(state) {
      return state.historyType
    },

    isInitialized(state) {
      return state.initialized
    }
  }
}
