import Emitter from "tiny-emitter"

import { NAVIGATION_EVENTS, HISTORY_TYPES } from "@/plugins/router/shared"

import { isPresent, isString } from "@/lib/utils"
import { SW_UPDATE_QUERY_PARAM } from "@/lib/constants"
import { getHashValue, toHashList } from "@/lib/url-helpers"

const afterEach = (store, emitter) => (_to, _from) => {
  if (!store.getters["router/isInitialized"]) {
    return store.commit("router/initialize", _to.path)
  }

  const to = store.getters["router/getPageIndex"](_to)
  const from = store.getters["router/getPageIndex"](_from)

  if (
    (from === -1 && to !== -1) || // Neither found in stack
    to === -1 || // "to" not found in stack
    to === from || // Replace
    to > from // "to" is ahead of "from" in stack
  ) {
    store.commit("router/push", `${_to.path}${_to.hash ? _to.hash : ""}`)
    if (_to.path !== _from.path) emitter.emit(NAVIGATION_EVENTS.PUSH)
  } else {
    store.commit("router/pop")
    if (_to.path !== _from.path) emitter.emit(NAVIGATION_EVENTS.POP)
  }
}

const toHashString = hashValues => `#${hashValues.join("+")}`

export default (router, store) => {
  if (store.getters["router/isInitialized"]) return

  const emitter = new Emitter()

  const routerPush = router.push.bind(router)
  const routerGo = router.go.bind(router)
  const routerReplace = router.replace.bind(router)
  const routerBack = router.back.bind(router)
  const routerForward = router.forward.bind(router)

  router.push = (location, options = {}) => {
    const { append = false, onResolve, onReject } = options || {}

    if (isPresent(location) && isString(location) && location.startsWith("#")) {
      const hashes = toHashList(router.currentRoute.hash)
      const hashValue = getHashValue(location)

      if (!hashes.includes(hashValue)) {
        if (append) {
          hashes.push(hashValue)
        } else {
          hashes[Math.max(hashes.length - 1, 0)] = hashValue
        }
      }

      router.replace(
        hashes.length > 0 ? toHashString(hashes) : null,
        onResolve,
        onReject
      )
    } else {
      store.commit("router/setHistoryType", HISTORY_TYPES.PUSH)

      if (onResolve || onReject) {
        return routerPush(location, onResolve, onReject)
      } else {
        return routerPush(location).catch(error => error)
      }
    }
  }

  router.go = n => {
    store.commit(
      "router/setHistoryType",
      n > 0 ? HISTORY_TYPES.PUSH : HISTORY_TYPES.POP
    )

    return routerGo(n)
  }

  router.replace = (location, onResolve, onReject) => {
    store.commit("router/setHistoryType", HISTORY_TYPES.REPLACE)

    if (onResolve || onReject) {
      return routerReplace(location, onResolve, onReject)
    }
    return routerReplace(location).catch(error => error)
  }

  router.back = () => {
    store.commit("router/setHistoryType", HISTORY_TYPES.POP)

    const stack = store.getters["router/stack"]

    // Go home if started from a different page
    if (
      stack.length === 1 &&
      router.currentRoute.path !== "/" &&
      !router.currentRoute.fullPath.includes(SW_UPDATE_QUERY_PARAM)
    ) {
      router.reset()
      return routerReplace("/")
    } else {
      return routerBack()
    }
  }

  router.forward = () => {
    store.commit("router/setHistoryType", HISTORY_TYPES.PUSH)

    routerForward()
  }

  router.reset = () => {
    store.commit("router/reset")
  }

  router.goHome = () => {
    store.commit("router/setHistoryType", HISTORY_TYPES.POP)

    routerReplace("/").then(() => {
      router.reset()
    })
  }

  router.removeHash = () => {
    return new Promise(resolve => {
      const hashes = toHashList(router.currentRoute.hash)
      hashes.pop()

      router.replace({
        hash: hashes.length > 0 ? toHashString(hashes) : null
      })

      resolve()
    })
  }

  router.queue = {
    state: () => store.state.router.queue,

    nextItem: () => store.state.router.queue[0],

    push: path => store.commit("router/queuePush", path),
    remove: path => store.commit("router/queueRemove", path)
  }

  router.on = (eventName, callback) => emitter.on(eventName, callback)
  router.off = (eventName, callback) => emitter.off(eventName, callback)

  router.afterHooks.unshift(afterEach(store, emitter))

  return router
}
