export const SYSTEM_FONT_STACK = `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif`

export const initEmbeddedGoogleFont = () => {
  const link1 = document.createElement("link")
  const link2 = document.createElement("link")

  link1.rel = "preconnect"
  link2.rel = "preconnect"

  link1.href = "https://fonts.googleapis.com"
  link2.href = "https://fonts.gstatic.com"

  link2.setAttribute("crossorigin", "")

  document.head.appendChild(link1)
  document.head.appendChild(link2)
}

export const generateEmbeddedLink = font => {
  // TODO: consider not using the params bellow
  // By using this, the font will display most accurately but will increase bandwidth (more imports)
  // If we don't use this, it just import the regular font, and use font-weight/font-style of CSS
  const params = "ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700"

  return `https://fonts.googleapis.com/css2?family=${font.replace(
    /\s/g,
    "+"
  )}:${params}&display=swap`
}

export const embedFont = font => {
  if (document.querySelector(`link[name="${font}"]`)) return

  const link = document.createElement("link")

  link.rel = "stylesheet"
  link.href = generateEmbeddedLink(font)
  link.crossOrigin = "anonymous"
  link.setAttribute("name", font)

  document.head.appendChild(link)
}
