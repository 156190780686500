import ApiClient from "@/lib/api-client"

import { isSupportedDevice, isNativePush } from "@/lib/is-platform"
import { isWithin } from "@/lib/date-utils"
import { set, get, LS_KEYS } from "@/lib/local-storage"
import isPreviewContext from "@/lib/is-platform/is-preview-context"

// Note: Must match backend/app/models/subscriber/notification_setting.rb
export const PROVIDERS = {
  EXPO: "expo",
  PUSHER: "pusher",
  FCM: "fcm"
}
// Note: Must match backend/app/models/subscriber/notification_setting.rb
export const STATUSES = {
  ENABLED: "enabled",
  DISABLED: "disabled",
  SUSPENDED: "suspended"
}

export const NOTIFICATION_GRANTED = "granted"

const LAST_DISMISS_THRESHOLD = {
  UNIT: 1,
  INTERVAL: "day"
}

export default {
  namespaced: true,

  state: {
    receiveBlastNotifications: true,
    receiveMessageNotifications: true,
    receiveReminderJustForYou: true,
    receiveReminderForApp: true,
    receiveReminderViaPushNotification: true,
    receiveReminderViaEmail: true,
    isDailyReminders: true,
    isWeeklyReminders: true,
    is15MinuteReminders: true,
    isStartingNowReminders: true,
    remindDayOf: true,
    receiveReminders: true,
    canAsk: false,
    status: null,
    token: null,
    lastAskedForPermission: get(LS_KEYS.ASKED_PUSH_PERMISSION),
    provider: null, // will be set in fetchPushSettings
    pusherDeviceId: null,

    hasLoadedSettings: false
  },

  mutations: {
    setCanAsk(state, canAsk) {
      state.canAsk = canAsk
    },

    setStatus(state, status) {
      state.status = status
    },

    setToken(state, token) {
      state.token = token
    },

    setProvider(state, provider) {
      state.provider = provider
    },

    setReceiveRemindersViaPushNotification(state, receivePush = true) {
      state.receiveReminderViaPushNotification = receivePush
    },

    setSettings(state, settings) {
      state.receiveBlastNotifications = !!settings.receive_blast_notifications
      state.receiveMessageNotifications = !!settings.receive_message_notifications
      state.receiveReminderJustForYou = !!settings.receive_reminders_just_for_you
      state.receiveReminderForApp = !!settings.receive_reminders_for_app
      state.receiveReminderViaPushNotification = !!settings.receive_reminders_via_push_notification
      state.receiveReminderViaEmail = !!settings.receive_reminders_via_email
      state.isDailyReminders = !!settings.is_daily_reminders
      state.isWeeklyReminders = !!settings.is_weekly_reminders
      state.is15MinuteReminders = !!settings.is_15_minute_reminder
      state.isStartingNowReminders = !!settings.is_starting_now_reminder
      state.remindDayOf = !!settings.remind_day_of
    },

    setPusherDeviceId(state, pusherDeviceId) {
      state.pusherDeviceId = pusherDeviceId
    },

    setHasLoadedSettings(state, hasLoadedSettings) {
      state.hasLoadedSettings = hasLoadedSettings
    },

    setLastAskedForPermission(state, lastAskedForPermission = +new Date()) {
      set(LS_KEYS.ASKED_PUSH_PERMISSION, lastAskedForPermission)
      state.lastAskedForPermission = lastAskedForPermission
    }
  },

  actions: {
    updatePushEndpointFromState({ state }) {
      return ApiClient.put("/notifications/push", {
        token: state.token,
        provider: state.provider,
        status: state.status,
        pusher_device_id: state.pusherDeviceId
      })
    },

    fetchPushSettings({ commit }) {
      return ApiClient.get("/notifications/push_settings").then(res => {
        commit("setStatus", res.data.push_status)
        commit("setProvider", res.data.provider)
        commit("setHasLoadedSettings", true)
      })
    },

    fetchNotificationSettings({ commit }) {
      return ApiClient.get("/notifications").then(res => {
        commit("setSettings", res.data)

        // Store last push notification status
        if (!res.data.is_all_reminders_inactive) {
          set(
            LS_KEYS.LAST_PUSH_ENABLED,
            !!res.data.receive_reminders_via_push_notification
          )
        }
      })
    },

    updateNotificationSettings({ commit }, settings) {
      commit("setSettings", settings)

      return ApiClient.put("/notifications", settings).then(res => {
        commit("setSettings", res.data)
      })
    },

    unsubscribeEmailNotifications(_, { id, type = null }) {
      return ApiClient.put(`/notifications/unsubscribe`, {
        subscriber_id: id,
        type: type
      }).then(res => res.data)
    }
  },

  getters: {
    hasRecentlyDismissed(state) {
      return state.lastAskedForPermission
        ? isWithin(
            parseInt(state.lastAskedForPermission),
            LAST_DISMISS_THRESHOLD.UNIT,
            LAST_DISMISS_THRESHOLD.INTERVAL
          )
        : false
    },

    hasAlreadyDismissed(state) {
      return !!state.lastAskedForPermission
    },

    canAskForPermission(state, _, __, rootGetters) {
      return (
        rootGetters["auth/isAuthenticated"] &&
        isSupportedDevice() &&
        (isPreviewContext() || isNativePush ? true : state.canAsk) &&
        state.status !== STATUSES.ENABLED
      )
    },

    shouldAskForPermission(_, getters) {
      return getters.canAskForPermission && !getters.hasRecentlyDismissed
    },

    isPushEnabled(state) {
      return () => {
        return !isSupportedDevice()
          ? false
          : isNativePush
          ? state.status === STATUSES.ENABLED
          : Notification?.permission === NOTIFICATION_GRANTED &&
            state.status === STATUSES.ENABLED
      }
    },

    isPushSuspended(state) {
      return () =>
        !isSupportedDevice()
          ? false
          : isNativePush
          ? state.status === STATUSES.SUSPENDED
          : Notification?.permission === NOTIFICATION_GRANTED &&
            state.status === STATUSES.SUSPENDED
    },

    hasLoadedPushSettings(state) {
      return state.hasLoadedSettings
    }
  }
}
