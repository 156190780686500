import { initializeApp } from "firebase/app"
import { getMessaging } from "firebase/messaging"

import { isNativePush, isSupportedDevice } from "@/lib/is-platform"
import config from "@/lib/fcm/config"

let messaging = {}

if (!isNativePush && isSupportedDevice()) {
  const app = initializeApp(config)

  messaging = getMessaging(app)
}

export default messaging
