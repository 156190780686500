import EventBus, { EVENTS } from "@/lib/event-bus"
import { handleNotificationUrl } from "@/lib/push-notification"
import store from "@/store"

let hasSetupListener = false
let hasSetupPushListener = false
export const NATIVE_APP_EVENT = "native-app-message"

// NOTE: Must match MESSAGE_TYPES in fitter-apps repo
export const MESSAGE_TYPES = {
  INITIALIZED: "initialized",
  IAP_PURCHASE_PROMPT: "iap-purchase-prompt",
  IAP_PURCHASE_CANCELED: "iap-purchase-canceled",
  IAP_PURCHASE_SUCCESS: "iap-purchase-success",
  IAP_INVALID_PRODUCT: "iap-invalid-product",
  PUSH_REQUEST_PERMISSION: "push-request-permission",
  PUSH_STATUS_REQUEST: "push-status-request",
  PUSH_STATUS_SET: "push-status-set",
  NAVIGATE_TO_URL: "navigate-to-url",
  OPEN_SETTINGS: "open-settings",
  GO_BACK: "go-back",

  // audio
  NATIVE_PLAYER_HAS_UPDATED: "native-player-has-updated",
  UPDATE_NATIVE_PLAYER: "update-native-player",

  AUTHENTICATED: "authenticated"
}

// NOTE: Must match IAP_PURCHASE_PROPERIES in fitter-apps repo
export const IAP_PURCHASE_PROPERTIES = {
  PRODUCT_ID: "productId",
  TRANSACTION_ID: "transactionId",
  ORIGINAL_TRANSACTION_ID: "originalTransactionId",
  RECEIPT: "receipt"
}

const handleInitialized = data => {
  store.commit("media/setIsNativePlayer", data.useNativePlayer)
}

export const initialize = vm => {
  window.addEventListener(NATIVE_APP_EVENT, event => {
    console.log(`Received Native Message: ${event.detail.type}`)

    switch (event.detail.type) {
      case MESSAGE_TYPES.INITIALIZED:
        handleInitialized(event.detail.data)
        break
      case MESSAGE_TYPES.NAVIGATE_TO_URL:
        handleNotificationUrl(event.detail.data.url)
        break
      case MESSAGE_TYPES.GO_BACK:
        vm.$router.back()
        break
      case MESSAGE_TYPES.AUTHENTICATED:
        EventBus.$emit(EVENTS.NATIVE_AUTHENTICATED, event.detail)

        break
    }
  })

  postMessageToNativeApp(buildMessage(MESSAGE_TYPES.INITIALIZED))
}

export const getWebview = () => window.ReactNativeWebView
export const postMessageToNativeApp = payload =>
  getWebview()?.postMessage(payload)

export const buildMessage = (type, data = {}) => JSON.stringify({ type, data })

export const setupNativePurchaseEventListeners = () => {
  if (hasSetupListener) return

  window.addEventListener(NATIVE_APP_EVENT, event => {
    console.log(`Received Native Message: ${event.detail.type}`)
    switch (event.detail.type) {
      case MESSAGE_TYPES.IAP_INVALID_PRODUCT:
        EventBus.$emit(EVENTS.IAP_INVALID_PRODUCT)
        break
      case MESSAGE_TYPES.IAP_PURCHASE_CANCELED:
        EventBus.$emit(EVENTS.IAP_PURCHASE_CANCELED)
        break
      case MESSAGE_TYPES.IAP_PURCHASE_SUCCESS:
        EventBus.$emit(EVENTS.IAP_PURCHASE_SUCCESS, event.detail.data)
        break
    }
  })

  hasSetupListener = true
}

export const setupPushStatusListener = callback => {
  if (hasSetupPushListener) return

  window.addEventListener(NATIVE_APP_EVENT, event => {
    console.log(`Received Native Message: ${event.detail.type}`)
    switch (event.detail.type) {
      case MESSAGE_TYPES.PUSH_STATUS_SET:
        callback(event.detail.data)
        break
    }
  })

  hasSetupPushListener = true
}

export const openSettings = () =>
  postMessageToNativeApp(buildMessage(MESSAGE_TYPES.OPEN_SETTINGS))

// https://stackoverflow.com/a/51127481
// https://developer.apple.com/documentation/storekit/in-app_purchase/original_api_for_in-app_purchase/subscriptions_and_offers/handling_subscriptions_billing
export const openIapManager = () =>
  window.open("https://apps.apple.com/account/subscriptions", "_blank")
