{
  "facebook": {
    "title": "Facebook",
    "name": "facebook"
  },
  "instagram": {
    "title": "Instagram",
    "name": "instagram"
  },
  "tiktok": {
    "title": "Tiktok",
    "name": "tiktok"
  },
  "youtube": {
    "title": "YouTube",
    "name": "youtube"
  },
  "twitter": {
    "title": "X(Twitter)",
    "name": "twitter"
  },
  "linkedin": {
    "title": "LinkedIn",
    "name": "linkedin"
  }
}
