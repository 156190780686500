import { loadStripe } from "@stripe/stripe-js"

import EventBus, { EVENTS } from "@/lib/event-bus"
import { VARIABLES } from "@/lib/globals"

import { mapGetters } from "vuex"

export const setupStripe = accountId => {
  if (window[VARIABLES.STRIPE]) return Promise.resolve()

  loadStripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY, {
    stripeAccount: accountId
  }).then(stripe => {
    // Prevent any occurence of race conditions while loading async
    if (!window[VARIABLES.STRIPE]) {
      window[VARIABLES.STRIPE] = stripe
      EventBus.$emit(EVENTS.STRIPE_LOADED)
    }
  })
}

export default onReadyFunc => ({
  data() {
    return {
      useStripe_stripe: window[VARIABLES.STRIPE]
    }
  },

  computed: {
    ...mapGetters(["canAcceptPayments"])
  },

  mounted() {
    EventBus.$on(EVENTS.STRIPE_LOADED, this.handleStripeLoaded)
  },

  beforeDestroy() {
    EventBus.$off(EVENTS.STRIPE_LOADED, this.handleStripeLoaded)
  },

  methods: {
    handleStripeLoaded() {
      this.useStripe_stripe = window[VARIABLES.STRIPE]
    }
  },

  watch: {
    canAcceptPayments: {
      immediate: true,
      async handler(canAcceptPayments) {
        if (canAcceptPayments) {
          if (this[onReadyFunc]) {
            this[onReadyFunc](this.useStripe_stripe)
          }
        }
      }
    }
  }
})
