import $ons from "@/plugins/onsen-ui"

const VueNotify = {
  install(Vue, { onDismiss, onShow }) {
    Vue.prototype.$notify = (message, options = {}) => {
      // Callback was firing on inital load too
      // This wrapper ensures its only called on hidden
      const callbackWrapper = callback => e =>
        Number.isInteger(e) ? callback(e) : null

      if (options.callback) {
        const providedCallback = options.callback

        options.callback = callbackWrapper(() => {
          if (onDismiss) onDismiss()

          providedCallback()
        })
      } else {
        if (onDismiss) {
          options.callback = callbackWrapper(onDismiss)
        }
      }

      $ons.notification.toast(message, {
        timeout: 5000,
        cancelable: true,
        buttonLabel: "OK",
        class: "v-toast",
        ...options
      })

      if (onShow) onShow()
    }
  }
}

export default VueNotify
