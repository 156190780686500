export const getReferralData = () => {
  if (typeof window.Rewardful !== "undefined") {
    return {
      referral: window.Rewardful.referral,
      affiliate: window.Rewardful.affiliate?.id,
      campaign: window.Rewardful.campaign?.id
    }
  } else {
    return {}
  }
}

export default apiKey => {
  const script1 = document.createElement("script")
  const script2 = document.createElement("script")

  script1.textContent =
    "(function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');"
  script2.async = true
  script2.src = "https://r.wdfl.co/rw.js"
  script2.setAttribute("data-rewardful", apiKey)

  document.head.appendChild(script1)
  document.head.appendChild(script2)
}
