import Vue from "vue"
import VueOnsen from "vue-onsenui/esm"

VueOnsen.platform.select("android")

Vue.use(VueOnsen)

const components = [
  "VOnsPage",
  "VOnsAlertDialog",
  "VOnsAlertDialogButton",
  "VOnsRipple",
  "VOnsToast",
  "VOnsProgressBar",
  "VOnsProgressCircular",
  "VOnsNavigator",
  "VOnsCheckbox"
]

components.forEach(component => {
  const importedComponent = require(`vue-onsenui/esm/components/${component}`)
    .default

  Vue.component(importedComponent.name, importedComponent)
})

export default VueOnsen
