import wrapRouter from "./router/router-wrapper"
import routerStore from "./router/router-store"

const VueStackRouter = {
  install(_, { router, store }) {
    wrapRouter(router, store)
    store.registerModule("router", routerStore)
  }
}

export default VueStackRouter
