import { isPresent } from "@/lib/utils"

export const DEFAULT_TABBAR_LINKS = [
  {
    text: "Home",
    icon: "home",
    path: "/"
  },
  {
    text: "Schedule",
    icon: "calendar_today",
    path: "/me/schedule"
  },
  {
    text: "You",
    icon: "person",
    path: "/me/my-content"
  }
]

export const isTabbarShown = ({
  currentPage,
  isVideoFullscreen,
  isDesktop
}) => {
  if (isVideoFullscreen) return false
  if (isDesktop) return true

  if (!currentPage) return true

  // Hide tabbar on workout pages or pages with actions
  if (currentPage.type === "workout") return false
  if (currentPage.actions?.length) return false

  return currentPage.show_tabbar
}

export default {
  namespaced: true,

  state: {
    showSidebar: false
  },

  mutations: {
    toggleSidebar(state) {
      state.showSidebar = !state.showSidebar
    },

    setShowSidebar(state, showSidebar = true) {
      state.showSidebar = showSidebar
    }
  },

  getters: {
    tabbarLinks(_, __, state) {
      return isPresent(state.global) &&
        isPresent(state.global.tabbar) &&
        isPresent(state.global.tabbar.links)
        ? state.global.tabbar.links
        : DEFAULT_TABBAR_LINKS
    },

    isTabbarShown(_, __, state) {
      return isTabbarShown({
        currentPage: state.pages.currentPage,
        isVideoFullscreen: state.videos.isFullscreen,
        isDesktop: state.responsive.isDesktop
      })
    }
  }
}
