import { cacheGlobalState } from "@/lib/page-cache"

export default () =>
  fetch(`${process.env.VUE_APP_APP_DATA_PATH}/global.js?ts=${+new Date()}`)
    .then(res => res.text())
    .then(body => {
      const state = JSON.parse(
        body
          .replace("window.FIT_globalState", "")
          .trim()
          .replace("=", "")
      )

      cacheGlobalState(state)

      return state
    })
