{
  "facebook": {
    "name": "facebook",
    "title": "Facebook",
    "default": true
  },
  "email": {
    "name": "email",
    "title": "Email",
    "default": true
  },
  "linkedin": {
    "name": "linkedin",
    "title": "LinkedIn",
    "default": false
  },
  "messenger": {
    "name": "messenger",
    "title": "Messenger",
    "default": true
  },
  "twitter": {
    "name": "twitter",
    "title": "Twitter",
    "default": true
  },
  "whatsapp": {
    "name": "whatsapp",
    "title": "Whatsapp",
    "default": true
  },
  "reddit": {
    "name": "reddit",
    "title": "Reddit",
    "default": false
  },
  "pinterest": {
    "name": "pinterest",
    "title": "Pinterest",
    "default": false
  },
  "sms": {
    "name": "sms",
    "title": "SMS",
    "default": false
  }
}
